import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Intro from "../views/Intro.vue";
import Blink from "../views/Blink.vue";

const server = process.env.VUE_APP_DATABASE;

const routes = [
  {
    path: "/",
    name: "Intro",
    component: Intro,
  },
  {
    path: "/blink",
    name: "Blink",
    component: Blink,
  },
  {
    path: "/prog",
    name: "Home",
    component: Home,
  },
  {
    path: "/admin",
    name: "About",
    component: () => import("../views/Admin.vue"),
    beforeEnter: async () => {
      var response = await fetch(server + "/auth", {
        credentials: "include",
      });
      if (response.status == 200) {
        return true;
      } else {
        router.push("/login");
      }
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/gestalten",
    name: "Input",
    component: () => import("../views/Input.vue"),
  },
  {
    path: "/step2",
    name: "Step2",
    component: () => import("../views/HardwareFinish"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
