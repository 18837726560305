<template>
  <h1>So funktionieren Leuchtpendel und Schüttel-mich</h1>

  <div class="text-aside">
    <div class="content">
      <p>
        Das schwingende Leuchtpendel verfügt über eine Anzeige aus lediglich
        sieben Leuchtdioden, die übereinander angeordnet sind. Lässt man das
        Pendel hin und her schwingen, erscheint der Anzeigetext, der im Raum zu
        schweben scheint.
      </p>
      <p>
        Bei genauer Betrachtung stellt man fest, dass am Pendel ein kleiner
        Permanentmagnet angebracht ist. Am feststehenden Teil der Anordnung
        befindet sich ein Hall-Sensor, der auf das Feld des Magneten reagiert.
        Nach dem Auslenken des Pendels in die Ausgangslage kann der
        Schwingvorgang beginnen. Damit für den Betrachter ein 'stehendes Bild'
        entsteht, wird der Anzeigevorgang immer dann gestartet, wenn das Pendel
        nach rechts schwingt und sich der Magnet über dem Hall-Sensor befindet.
      </p>
    </div>
    <div class="media-content">
      <video
        src="https://schuettel-mich.com/img/Leuchtpendel.mp4"
        controls
        controlsList="nodownload"
      ></video>
      <img src="@/assets/Bild1.png" alt="" class="image" />
    </div>
  </div>
  <div class="text-aside">
    <div class="content">
      <p>
        Nun werden nacheinander die Spaltenmuster der Zeichen für kurze Zeit
        angezeigt. Durch die Trägheit des Auges entsteht für den Betrachter ein
        lesbarer Schriftzug. Beim Zurückschwingen in die Ausgangslage bleibt die
        Anzeige ausgeschaltet.
      </p>
      <p>
        Beim Schüttel-mich wird der Hall-Sensor durch einen Lagesensor S1
        ersetzt. In seinem Innern befindet sich eine bewegliche Metallkugel.
        Beginnt man eine Rechtsbewegung, schließt die Kugel einen elektrischen
        Kontakt, der Anzeigevorgang kann beginnen.
      </p>
    </div>
    <div class="media-content">
      <img src="@/assets/Bild2.jpg" alt="" />
      <img src="../assets/v2/Startseite/Bild_Startseite.jpg" alt="" />
    </div>
  </div>
  <div class="start-container">
    <button @click="$router.push('/step2')">Weiter</button>
  </div>
</template>

<script>
export default {
  name: "Intro",
};
</script>

<style scoped>
.text-aside {
  display: flex;
  flex-direction: column;
  margin-bottom: 2%;
}
.media-content {
  display: flex;
  flex-direction: row;
  height: 400px;
  justify-content: space-around;
}
img,
video {
  height: 100%;
  width: auto;
}
.start-container {
  background-color: #005799;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 16px;
  border-radius: 5px;
}
button {
  width: 20%;
  height: 50px;
  cursor: pointer;
}
@media only screen and (max-width: 800px) {
  .media-content {
    flex-direction: column;
    height: auto;
    row-gap: 16px;
  }
  .start-container button {
    width: 100%;
  }
}
@media only screen and (max-width: 425px) {
  .media-content {
    flex-direction: column;
    height: auto;
    row-gap: 16px;
  }
}
</style>
