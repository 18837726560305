<template>
  <div id="app">
    <header id="header">
      <router-link to="/" id="logo-link">
        <img
          src="@/assets/wss-logo.svg"
          alt="Werner-Siemens-Schule Stuttgart"
          id="logo"
        />
      </router-link>
    </header>
    <router-view />
    <footer>
      <router-link
        v-if="this.$route.fullPath !== '/admin'"
        to="/admin"
        id="login"
        >Login</router-link
      >
      <router-link v-else to="/prog" id="login">Zurück</router-link>
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
* {
  box-sizing: border-box;
}
#app {
  padding-right: 5%;
  padding-left: 5%;
  font-family: Arial, Helvetica, sans-serif;
}
#header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
#logo {
  width: 100%;
}
footer {
  height: 50px;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  #app {
    padding-right: 10px;
    padding-left: 10px;
  }
}
</style>
