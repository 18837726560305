<template>
  <div class="container">
    <div class="black">
      <div v-if="on" class="blinkOn" :style="{ backgroundColor: color }" />
      <div v-else class="blinkOff"></div>
    </div>
    <div class="control">
      <div>
        <label for="color">Farbe: </label>
        <input type="color" name="color" id="color" v-model="color" />
      </div>
      <div class="matrix">
        <Matrix :matrixData="matrix" @updateData="updateLetter" />
      </div>
      <div>
        <button @click="startBlink">Start</button>
        <button @click="stopBlink">Stop</button>
        <!--<button @click="logMatrix">LogMatrix</button>
        <button @click="prepareMatrix">Prepare</button>-->
        <button @click="reset">Reset</button>
      </div>
    </div>
  </div>
</template>

<script>
import Matrix from "@/components/Matrix.vue";
export default {
  components: {
    Matrix,
  },
  data() {
    return {
      on: true,
      color: "#ffffff",
      matrix: [
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
      ],
      initStream: [
        [0, 1, 0, 1, 0, 1, 0, 1], //je 0,1s
        [0, 1, 0, 1, 0, 1, 0, 1, 0], //0x55
        [0, 0, 1, 0, 1, 0, 1, 0, 1], //0xAA
        [0, 1, 0, 1, 0, 1, 0, 1, 0], //0x55
        [0, 0, 1, 0, 1, 0, 1, 0, 1], //0xAA
        [0, 1, 0, 1, 0, 1, 0, 1, 0], //0x55
        [0, 0, 1, 0, 1, 0, 1, 0, 1], //0xAA
      ],
      startByte: [0, 0, 0, 0, 0, 0, 0, 1, 1],
      requestId: 0,
    };
  },
  methods: {
    startBlink: function () {
      this.sendMessage();
    },
    stopBlink: function () {
      cancelAnimationFrame(this.requestId);
    },
    sendMessage: async function () {
      var startTime = Date.now();
      console.log("Start");
      const dataMatrix = this.prepareMatrix();
      //Bits zur Zeitmessung-Sync senden -> initStream
      await this.delay(500);
      for (let byte of this.initStream) {
        await this.delay(500);
        var start = Date.now();
        for (let bit of byte) {
          console.log(bit);
          this.on = bit ? true : false;
          await this.delay(100);
        }
        console.log(Date.now() - start);
        this.on = true;
        console.log("Pause");
      } //Ende InitStream
      await this.delay(200);
      //Start-Byte senden
      for (let bit of this.startByte) {
        console.log(bit);
        this.on = bit ? true : false;
        await this.delay(100);
      }
      await this.delay(100);
      console.log("Matrix Start");
      //LED-Matrix senden
      for (var counter = 0; counter < 7; counter++) {
        console.log("Buchstabe" + counter);
        for (let col of dataMatrix) {
          for (let bit of col) {
            this.on = bit ? true : false;
            console.log(bit);
            await this.delay(100);
          }
          this.on = true;
          console.log("Pause");
          await this.delay(100);
        }
        await this.delay(100);
      }
      console.log("Ende");
      console.log((Date.now() - startTime) / 1000);
    },
    delay: function (delay) {
      return new Promise((resolve) => {
        var time = Date.now();
        var animate = function () {
          var delta = Date.now() - time;
          if (delta < delay - 5) {
            requestAnimationFrame(animate);
          } else {
            resolve();
          }
        };
        this.requestId = requestAnimationFrame(animate);
      });
    },
    updateLetter: function (data) {
      this.matrix = data.matrix;
    },
    prepareMatrix: function () {
      const workMatrix = this.matrix.map((o) => [...o]); //Copy ohne Reference
      for (let byte of workMatrix) {
        byte.unshift(0); //0 an erste Stelle
        byte.push(0); //0 an letzte Stelle
      }
      return workMatrix;
    },
    logMatrix: function () {
      console.log(this.matrix);
    },
    reset: function () {
      this.matrix = [
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
      ];
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: row;
}
.black {
  min-width: 500px;
  height: 800px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blinkOn,
.blinkOff {
  width: 50px;
  height: 50px;
}
.blinkOff {
  background-color: #000000;
}

.control {
  margin-left: 50px;
  margin-top: 50px;
}
.matrix {
  height: 300px;
  width: 200px;
}
</style>
