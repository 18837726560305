<template>
  <div class="home">
    <div>
      <h1>Das will ich sehen!</h1>
      <p>
        Lege nun den Schriftzug fest, der angezeigt werden soll. Das könnte z.B.
        Dein Name sein. Der Schriftzug kann maximal sieben Zeichen umfassen.
        Unten auf der Seite findest Du Vorschläge für die Darstellung von
        Buchstaben und Ziffern sowie einiger Sonderzeichen. Selbstverständlich
        kannst Du auch eigene Zeichen festlegen.
      </p>
    </div>
    <div class="interactive">
      <div v-for="(item, index) in 7" :key="index" class="matrix-letter">
        <Matrix
          :matrixData="letters[index]"
          :letter-position="index"
          @updateData="updateLetter"
        />
      </div>
    </div>
    <div class="saveData">
      <input
        type="text"
        name="name"
        id="name-field"
        placeholder="Dein Name"
        v-model="name"
      />
      <button @click="saveData()">Speichern</button>
    </div>
  </div>
  <div class="example-image">
    <img src="@/assets/BuchstabenA_Z_lang.jpg" alt="" />
  </div>
</template>

<script>
import Matrix from "@/components/Matrix.vue";

export default {
  name: "Home",
  components: {
    Matrix,
  },
  data() {
    return {
      name: "",
      letters: [
        [
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
        ],
        [
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
        ],
        [
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
        ],
        [
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
        ],
        [
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
        ],
        [
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
        ],
        [
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
        ],
      ],
      server: process.env.VUE_APP_DATABASE,
    };
  },
  methods: {
    updateLetter: function (data) {
      this.letters[data.position] = data.matrix;
    },
    saveData: async function () {
      if (this.name) {
        var response = await fetch(this.server + "/newUser", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name: this.name, data: this.letters }),
        });
        if (response.status == 200) {
          alert("Gespeichert!");
        }
      } else {
        alert("Bitte einen Namen eingeben");
      }
    },
  },
};
</script>

<style scoped>
.interactive {
  display: flex;
  flex-direction: row;
  row-gap: 16px;
  justify-content: space-between;
  width: 100%;
}
.matrix-letter {
  width: 12%;
  min-height: 300px;
}

@media only screen and (max-width: 1200px) {
  .interactive {
    flex-wrap: wrap;
  }
  .matrix-letter {
    width: 30%;
    min-height: 300px;
  }
}
@media only screen and (max-width: 500px) {
  .interactive {
    flex-wrap: wrap;
  }
  .matrix-letter {
    width: 100%;
  }
}

.saveData {
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  justify-content: right;
}
#name-field {
  height: 40px;
  margin-right: 1%;
}
button {
  height: 40px;
}
.example-image img {
  width: 100%;
}
</style>
