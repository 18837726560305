<template>
  <div class="matrix-container">
    <div
      class="matrix-row"
      v-for="(rowItems, rowIndex) in matrixData"
      :key="rowIndex"
    >
      <div
        class="matrix-element"
        v-for="(item, index) in rowItems"
        :key="index"
        :style="{ backgroundColor: statusColor(item) }"
        @click="changeElementStatus(rowIndex, index)"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Matrix",
  props: {
    matrixData: {
      type: Array,
      default() {
        return [
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0],
        ];
      },
    },
    letterPosition: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      updateMatrix: [
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
      ],
    };
  },
  methods: {
    statusColor: function (val) {
      return val == 0 ? "white" : "red";
    },
    changeElementStatus: function (row, position) {
      this.updateMatrix[row][position] =
        this.matrixData[row][position] === 0 ? 1 : 0;
      this.$emit("updateData", {
        matrix: this.updateMatrix,
        position: this.letterPosition,
      });
    },
  },
};
</script>

<style scoped>
.matrix-container {
  width: 100%;
  height: 100%;
  border: solid 1px black;
  display: flex;
  flex-direction: row;
  padding: 1.5%;
}
.matrix-row {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.matrix-element {
  width: 90%;
  height: 12%;
  border: solid 1px black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
